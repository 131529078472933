<script>
import { Scatter } from 'vue-chartjs'
import Chart from 'chart.js'

let myScatterExtend = Chart.controllers.scatter.prototype.draw;
Chart.helpers.extend(Chart.controllers.scatter.prototype, {
  draw: function() {
    myScatterExtend.apply(this, arguments);
    // text styles below
    
    let chart = this.chart;
    let width = chart.chart.width
    let height = chart.chart.height
    let ctx = chart.chart.ctx;

    let isAllNull = true;

    if (chart.id != 1) return // This seems to be triggering for each scatter and not the chart as a whole...
    debugger
    for (let point of this._data) {
        if (!isNaN(point.y)) isAllNull = false;
    }

    if (isAllNull) {
        // ctx.clear();

        // var text = chart.config.data.text,
        let text = "Market data could not be loaded";
        
        ctx.textAlign = "left"
        ctx.font = "24px Arial black";
        let textX = Math.round((width - ctx.measureText(text).width) / 2)
        let textY = height / 2;
        ctx.fillText(text, textX, textY)  // text, x-pos, y-pos
        console.log("hi?")
    }
  }
});

export default {
    extends: Scatter,
    props: [ 'datasets' ],
    data() {
    },
    async mounted () {
        const options = {
            scales: {
                yAxes: [{
                    grace: '5%',
                    gridLines: {
                        drawBorder: false,
                    },
                    ticks: {
                        display: true,
                        fontColor: "black",
                        beginAtZero: true,
                        callback: function(label, index, labels) {
                            return '$' + label;
                        }
                    }
                }],
                xAxes: [{
                    id: 'ScartCharxAxes',
                    type: 'time',
                    time: {
                        unit: 'day',
                        displayFormats: {
                            day: 'MMM DD'
                        },
                    },
                    ticks: {
                        fontColor: "black",
                        autoSkip: true,
                        maxRotation: 0,
                        minRotation: 0,
                        maxTicksLimit: 20
                    },
                    gridLines: {
                        // tickMarkLength: 50
                    }
                }]
            },
            title: {
                display: false,
                text: 'Past Market Sales (Steam, Guard.GG)',
                align: 'left'
            },
            legend: {
                position: 'top',
                align: 'end',
                labels: {
                    usePointStyle: true,
                    fontColor: 'black',

                    filter: function(item, chart) {
                        // Logic to remove a particular legend item goes here
                        return !item.text.includes('average');
                    }
                }
            },
            layout: {
                padding: {
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 20
                }
            }
        }
                    
        const data = {
            // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
            datasets: this.datasets,
            // datasets: [
            //     {
            //         label: 'Steam Price',
            //         data: mock.chart(),
            //         pointBackgroundColor: '#91A1B3',
            //         backgroundColor: '#91A1B3',
            //     },
            //     {
            //         label: 'Guard.GG Price',
            //         data: mock.chart(),
            //         pointBackgroundColor: '#7BAA64',
            //         backgroundColor: '#7BAA64',
            //     }
            // ]
        }

        this.renderChart(data, options)
    }
}
</script>

<style>

</style>