<template>
    <div v-if="isLoading">
        <span class="text-white">Loading</span>
    </div>
    <div v-else-if="isErrored">
        <span class="text-white">Error Loading</span>
    </div>
    <div v-else>
        <Chart :datasets="datasets" :height="100" />
    </div>
    
</template>

<script >

function average(datasets) {
    let points = {};

    const datasetsToAverage = datasets.length;

    for (let i = 0; i < datasetsToAverage; i++)
        for (let point of datasets[i].data) {
            points[point.x] = points[point.x] || 0;
            points[point.x] += parseFloat(point.y);
        }

    for (let point of Object.keys(points)) {
        points[point] = parseFloat(points[point]) / datasetsToAverage;
    }

    return Object.keys(points).map(point => { return { x: point, y: points[point] } })

}

import Chart from "./Chart.vue";
import api from "../api";
import * as mock from "../mock";

export default {
    props: [ 'app_id', 'market_hash_name' ],
    components: { Chart },
    data() {
        return {
            isLoading: false,
            isErrored: false,
            datasets: [],
        }
    },
    async mounted() {
        try {
            this.isLoading = true;
            console.log(`Loading pricing history for ${this.app_id}:${this.market_hash_name}`)
            let data = (await api.getPricingHistory(this.app_id, this.market_hash_name)).data;
            
            this.datasets.push({
                label: 'Steam Average Sale Price',
                data: Object.keys(data.steam).map(date => ({ x: date, y: data.steam[date] })),
                pointBackgroundColor: '#91A1B3',
                backgroundColor: '#91A1B3',
                showLine: true,
                pointHitRadius: 20, //set as you wish
                fill: false,
                tension: 0,
            });
            this.datasets.push({
                label: 'Guard.GG Price',
                data: Object.keys(data.guard).map(date => ({ x: date, y: data.guard[date] })),
                pointBackgroundColor: '#7BAA64',
                backgroundColor: '#7BAA64',
            });


                // this.datasets.push({
                //     type: 'line',
                //     label: 'average',
                //     fill: false,
                //     borderColor: '#ED6B33',
                //     pointRadius: 0,
                //     lineTension: 0,
                //     borderWidth: 1,
                //     data: average(this.datasets)
                // });
        } catch (e) {
            console.log(e.stack);
            this.isErrored = true;
        } finally {
            this.isLoading = false;
        }
    }
    
}
</script>