<template>
  <div v-if="item && !loading" style="overflow-y: auto; overflow-x: hidden; height:100%; width:100%" ref="itemDb" id="itemDb">
    <div class="container">
      <div class="row mt-5">
        <div class="col-9">
          <div class="card p-3" 
            style="
              height: 300px; 
              border: 0;
              border-radius: 15px; 
              background-color: rgba(238, 238, 238, 0.88) !important; 
              box-shadow: 0px 5px 8px #C0C0C0;
              background-blend-mode: color;
              background-repeat: no-repeat;
              background-position: top -190px right -300px;
            "
            :style="{ backgroundImage: `url('${csgomen}')` }"
          >

          <!-- Main item -->
          <div class="d-flex">
            <div class="mx-auto" style="height:100%">
              <img v-if="item" :src=" ('https://steamcommunity-a.akamaihd.net/economy/image/'+item.image+'/512fx512fdpx2x')" alt="" style="height:300px;margin-top:-20px;object-fit: cover;">
            </div>

            <div v-if="item" class="w-50 p-3">
              <div class="ml-3">
                <div class="mt-4"><span class="h4" style="font-family: Roboto;font-style: normal;font-weight: 900;font-size: 24px;letter-spacing: 0.5px;color: #545454;">{{item.market_hash_name}}</span></div>
                <!-- <div v-if="pricerange" class="mt-2">
                  <span class="c-secondary h4 font-weight-normal" style="font-family: Roboto;font-style: normal;font-weight: bold;font-size: 24px;color: #7BAA64;">${{pricerange.min}} - ${{pricerange.max}}</span>
                </div> -->
                <div class="mt-2"><span style="color: #545454" v-if="count">(<u>{{count}} Available</u>)</span></div>
                <div class="mt-2 d-flex justify-space-between">
                  <div v-if="item.collection">
                    <span style="font-family: Roboto;font-style: normal;font-weight: 500;font-size: 14px;letter-spacing: 1px;color: #545454; margin-right:35px;"> 
                      <img :src="item.collection.image" alt="" style="height:18px; margin-right:5px;">{{item.collection.name}}
                    </span>
                  </div>
                  <div class="d-flex">
                    <div class="item-rarity" style="">
                      <svg height="10" width="10">
                        <circle
                          cx="5"
                          cy="5"
                          r="4"
                          stroke="black"
                          stroke-width="1"
                          :fill="'#' + item.rarity.color"	
                        />
                      </svg>
                    </div>
                    <div v-if="item.appId == 440" class="item-rarity font-weight-normal" style="margin-left: 4px;font-family: Roboto;font-style: normal;font-weight: 500;font-size: 14px;letter-spacing: 1px; padding-top: 1px; padding-left: 5px;"><span v-if="item.level">Level {{item.level}}</span> {{item.rarity.name}}</div>
                    <div v-else class="item-rarity font-weight-normal" style="margin-left: 4px;font-family: Roboto;font-style: normal;font-weight: 500;font-size: 14px;letter-spacing: 1px; padding-top: 1px; padding-left: 5px;">{{item.rarity.name}}</div>
                  </div>
                </div>

                <div class="d-flex" style="margin-top: 15px; max-width:250px;" v-if="item.state == 'LISTED'">
                  <div v-if="item.appId !== 252490 && item.appId !== 433850 && item.appId !== 440" class="item-tradability mr-auto" :class="{ 'mx-auto': item.stickers ? item.stickers.length == 0 : true}" >
                    <span v-if="isTradable" class="tradable-text">
                      <img src="@/assets/unlocked.svg" style=" margin: 0px;"  /> Tradable
                    </span>
                    <span v-else-if="isTradable == null" class="tradable-text">
                      <img src="@/assets/errorlocked.svg" style="margin-right:5px;" /> Unknown
                    </span>
                    <span v-else class="tradable-text">
                      <img src="@/assets/locked.svg" style=" margin: 0px;" /> Un-Tradable
                    </span>
                  </div>
                  <div class="stickers-display">
                    <div v-for="(sticker, i) of item.stickers" v-bind:key="i" class="sticker-display">
                      <img :src="sticker.image"
                      style="max-width: 50px"
                      v-tooltip="item.appId == 730 ? sticker.name + parseStickerScrape(sticker.wear) : sticker.name"
                      :class="item.appId === 440 ? getTf2BackdropClass(sticker.name) : ''"
                      />
                      <font-awesome-icon v-if="sticker.wear" icon="exclamation-circle" class="sticker-wear-alert-icon" style="color: #ED6B33; max-height: 12px;"/>
                    </div>
                  </div>
                </div>

                <div class="mt-3" style="margin-left: 16px;"> <!-- Float bar -->
                  <div v-if="item.float || item.patternIndex">
                    <!-- <div class="hr mt-2"></div> -->
                    <div class="float-info" style="margin-right: -40px; margin-left: -15px;">
                      <div class="progress" style="height: 10px;position: relative;">
                        <div v-for="(value, key) in item.floatRanges" :key="key"
                        v-tooltip="value.name + ' (' + value.range[0] + ' - ' + value.range[1] + ')'"
                        :style="{'width': getProgressBarWidth(value.range), 'background-color': getFloatRangeBackgroundColor(value.name), 'border-radius': getProgressBarBorderRadius(key), 'opacity': value.striped ? 0.8 : 1}"
                        class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"
                        :class="{'progress-bar-striped': value.striped}"
                        ></div>
                        <img src="../assets/float_arrow.svg" class="float-image" :style="{ 'left': (220-(item.float*100*2.32)) + 'px' }">
                      </div>

                      <div class="float-text">
                        <!-- We do the pad and not toFixed as toFixed makes up 0's we dont know exist -->
                        <!-- Pad end will be like 0.213 or 0.0228293 -->
                        <span v-if="item.float" class="f1" style="color:#545454">Float Value: {{(parseFloat(item.float).toFixed(5) + '').padEnd(5, 'z').replace(/z/gmi, '') }}</span>
                        <span v-if="item.patternIndex" class="f1" style="color:#545454">Pattern Index: #{{item.patternIndex}}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Stickers -->
                <!-- <div class="item-stickers">
                  <div v-for="(sticker, i) of cappedStickers" v-bind:key="i" class="item-sticker">
                    <img :src="sticker.image"
                    style="max-width: 50px;"
                    v-tooltip="appid == 730 ? sticker.name + parseStickerScrape(sticker.wear) : sticker.name"
                    :class="appId === 440 ? getTf2BackdropClass(sticker.name) : ''"
                    />
                    <font-awesome-icon v-if="sticker.wear" icon="exclamation-circle" class="sticker-wear-alert-icon" style="color: #ED6B33; max-height: 12px;"/>
                  </div>
                </div> -->

                <div v-if="item.renamed" class="ml-auto">
                  <img src="@/assets/nametag.png" style="max-height: 20px;" v-tooltip="'Item has a custom nametag. Please inspect in game to view.'"/>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
        <div class="col-3">
          <!-- Pricing -->
          <div>
            <div class="card" style="border-radius: 5px; height:300px; width: 100%; background: #F7F7F7;box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.16);">
              <div class="card-body" style="background-color: rgb(247,247,247)">
                <div class="mx-auto">
                  <div class="text-center mt-3" style="font-family: Roboto;font-style: normal;font-weight: bold;font-size: 36px;color: #7BAA64;">${{numeral(item.listPrice).value().toFixed(2)}}</div>

                  <div class="d-flex justify-content-center">
                    <div class="item-discount text-center mt-0" v-if="item.discount && !isNaN(item.discount) && item.discount != -Infinity && item.discount != Infinity && item.discount > 0">SAVE {{Math.ceil(item.discount)}}%</div>
                  </div>
                  
                  <div class="text-center mt-4">
                    <div style="width: 190px;  text-align:center; background-color: ED6B33; height:40px;padding-top:12px;"
                      v-if="item.state != 'LISTED' || (user && item.userId == user.id)"
                      class="btn btn-dark btn-xs item-button checkout-button"
                      disabled
                    >Not available</div>
                    <div style="width: 190px;  text-align:center; background-color: ED6B33; height:40px;padding-top:12px;"
                      v-else-if="!isInCart"
                      class="btn btn-orange btn-xs item-button checkout-button"
                      @click="addToCart"
                    >Add to cart</div>
                    <div
                      style="width: 190px;  text-align:center; background-color: ED6B33; height:40px;padding-top:12px;"
                      v-else
                      class="btn btn-primary btn-xs item-button checkout-button"
                      @click="checkOut"
                    >Check out</div>
                  </div>
                  <router-link class="mt-3" :to="searchSimilarUrl" v-slot="{ href, route, navigate, isActive, isExactActive }">
                    <div class="text-center mt-3" style="cursor: pointer" @click="navigate">
                      <div style="font-family: Roboto;font-style: normal;font-weight: normal;font-size: 14px;color: #545454;cursor: pointer;"> <icon-magnify class="ml-3" />Search Similar</div>
                    </div>
                  </router-link>
                </div>
              </div>
              <div class="card-footer d-flex justify-content-around" style=" background: #F7F7F7;border-bottom-right-radius: 5px;;border-bottom-left-radius: 5px;">
                  
                  <template v-if="item.state == 'LISTED' && (!user || item.userId != user.id)">
                    <div v-if="watchlistLoading" v-tooltip="'Add to Watchlist'" class="item-icon-base" style="height: 30px; width: 30px; background-color: #091629; border-radius:50%; padding-left: 4px; padding-top:2px; margin-right: 4px;">
                      <div class="spinner-border text-white spinner-border-sm" style="font-size: 12px; margin-left: 3px;" role="status">
                    </div>
                    </div>
                    <div v-else-if="!isInWatchlist" v-tooltip="'Add to Watchlist'" class="item-icon-base" style="height: 30px; width: 30px; background-color: #091629; border-radius:50%; padding-left: 4px; padding-top:2px; margin-right: 4px;">
                      <icon-plus class="icon-custom" @click="addToWatchlist"/>
                    </div>
                    <div v-else v-tooltip="'Remove from Watchlist'" class="item-icon-base" style="height: 30px; width: 30px; background-color: #091629; border-radius:50%; padding-left: 4px; padding-top:2px; margin-right: 4px;">
                      <icon-minus class="icon-custom" @click="removeFromWatchlist"/>
                    </div>
                  </template>
                  <div v-tooltip="'Inspect In-Game'" @mouseenter="steam = true" @mouseleave="steam=false" @mouseup="inspectItem()">
                    <font-awesome-layers style="font-size: 2rem; margin-top: -1px; cursor: pointer;">
                      <font-awesome-icon icon="circle" :style="{ color: steam ? '#ED6B33' : '#4A4A4A' }" />
                      <font-awesome-icon :icon="[ 'fab', 'steam-symbol' ]" transform="shrink-6" :style="{ color: 'white' }" />
                    </font-awesome-layers>
                  </div>
              </div>
            </div>
          </div>
          <!-- /Pricing -->

        </div>
      </div>

      <div class="mt-4">
        <!-- <div><h2>Marketplace Trend</h2></div> -->
        <div style="height: 380px; 
          background: url(csgo_gsg9_1440_nologo.jpg), rgba(244, 244, 244, 0.9);
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
          border-radius: 10px; 
          padding: 10px;">

          <div class="mt-3s ml-3">
            <span class="h4 font-weight-normal">Past Market Sales</span>
          </div>

          <div style="max-width: 100%;"><Chart :app_id="game.id" :market_hash_name="item.market_hash_name" :height="100"></Chart></div>
        </div>
      </div>
    </div>

    <!-- Items -->
    <div class="container mt-5">
      <ScrollGameView :notitle="true" :game="game" type="specific" :item="item"/>
    </div>
    
    <!-- Extra stuff -->
    
    <div class="container mt-5">
      <ScrollGameView :notitle="true" :game="game" type="similar" :item="item"/>
    </div>
  </div>

  <div v-else-if="error" class="w-100">
    <div class="mt-5 mx-auto text-center">
      {{error}}
    </div>
  </div>
  <div v-else>
    <div class="mt-5">
      <div class="loader"></div>
    </div>
  </div>
</template>

<script>
import ScrollGameView from '@/components/ScrollGameView.vue'
import moment from 'moment';
import * as mock from '../mock';
import api from "../api";

import Chart from '../components/PricingHistoryChart.vue';

import csgomen from '../assets/csgomen.jpeg'
require('../assets/csgomen.jpeg');
import numeral from "numeral";
import parseItems from '../utils/parseItems';
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      item: null,
      csgomen,
      pricingInformation: false, 
      steam: false,
      loading: true,
      error: null,
      humanTradableTime: null,
      count: null,
      watchlistLoading: false,
    }
  },
  async mounted() {
    await this.loadItemFromRoute();
  },
  components: {
    Chart,
    ScrollGameView
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    game() {
      if (!this.item) {
        return;
      }

      let game = {};
      
      switch(this.item.appId) {
        case 730:
          game.name = 'CS:GO';
          game.page = 'csgo';
          break;
        case 440:
          game.name = 'TF2';
          game.page = 'tf2';
          break;
        case 570:
          game.name = 'DOTA 2';
          game.page = 'dota2';
          break;
        case 252490:
          game.name = 'RUST';
          game.page = 'rust';
          break;
        default:
          game.name = 'N/A';
          game.page = 'na';
          break;
      }

      return game;
    },
    searchSimilarUrl() {
      return `/${this.game.page}?market_hash_name=${this.item.market_hash_name}`
    },
    isInCart() {
      return this.$store.getters["cart/inCart"](this.item);
      // return false;
    },
    isInWatchlist: {
      get: function() {
        return this.$store.getters["watchlist/inWatchlist"](this.item);
      },
      set: function(newValue) {

      }
    },
    isInSellCart(){
      return false;
    },
    isTradable() {
      let isTradable = true;
      let lockedUntil;
      if (this.lockedUntil !== false){
        lockedUntil = moment(this.lockedUntil)
      }
      else if (this.appId !== 730 && this.appId !== 440 && this.appId !== 570) {
        this.humanTradableTime = "Tradable Now";
        return true;
      }
      else{
        return null; // Error-locked
      } 

      if (lockedUntil.isValid()) {
        let isAfter = lockedUntil.isAfter(moment());
        if (isAfter) {
          isTradable = false;
        }
      }
      else{
        isTradable = null;
      }

      if (this.developer) isTradable = false;
      
      if (!isTradable) {
        this.humanTradableTime = lockedUntil.isValid() ? "Tradable " + lockedUntil.fromNow() : "Not Tradable";
      }

      return isTradable;

      // Non-CSGO/TF2/Dota2: Always tradable
      // CSGO/TF2/Dota2 Error: Orange icon
      // CSGO/TF2/Dota2 Locked: red icon + Tradable in + date
      // CSGO/TF2/Dota2 Tradable: Tradable Now + green icon 
    },

    cappedStickers() {
      if (!this.item) return [];
      if (!this.item.stickers) return [];
      if (this.item.stickers.length > 4) {
        let cappedStickers = this.item.stickers.slice(0, 4);
        let remainingStickerNames = this.item.stickers.slice(4, this.stickers.length).map(x => x.name);

        cappedStickers.push({
          name: remainingStickerNames.join("\n"),
          image: require("@/assets/more_icon.png")
        })
        return cappedStickers;
      }

      return this.item.stickers;
    },
  },
  methods: {
    async loadCount() {
      this.count = (await api.getItems({ market_hash_name: this.item.market_hash_name, excludeOwned: true, state: "LISTED" })).data.length
    },
    async loadItemFromRoute() {
      this.loading = true;
      if (this.$route.query.itemId) {
        console.log("getting item")
        let items = (await api.getItems({ids: this.$route.query.itemId})).data;
        items = await parseItems(items);
        this.item = items[0];
        console.log("setting item")
        console.log(this.item);
        this.game.id = this.item.appid || this.item.appId;
        this.loadCount();
        this.loading = false;
      } else {
        this.loading = false;
        this.error = "No such item exists";
      }
    },
    getProgressBarWidth(values){
      return (values[0]-values[1])*100+'%'
    },
    getProgressBarBorderRadius(float){
      let border = "0 0 0 0" // Default border
      switch(float){
        case "BS":
          border = "10px 0 0 10px";
          break;
        case "FN":
          border = "0 10px 10px 0"
          break;
      }

      return border
    },
    getFloatRangeBackgroundColor(wearName){
      let color = "#000000"; // Error color
      switch(wearName.substring(0,2)){
        case "BS":
          color = "#cc5b5b";
          break;
        case "WW":
          color = "#e5724c"
          break;
        case "FT":
          color = "#e6ce62"
          break;
        case "MW":
          color = "#9ab88c"
          break;
        case "FN":
          color = "#85a377"
          break;
      }

      return color;
    },
    getTf2BackdropClass(name){
      if (name.split(' ')[1] == "Unusual"){
        return "tf2-unusual-backdrop"
      }

      return "tf2-part-backdrop"
    },
    parseStickerScrape(wear){
      if(wear === null){
        return "";
      }

      wear = Math.ceil(wear*10)*10;

      if (isNaN(wear)) {
        return ""; // Avoid things like NaN % scraped
      }

      return " (" + wear + "% scraped)";
    },
    inspectItem(){
      console.log("Item inspected")
    },
    numeral,
    async addToCart() {
      await this.$store.dispatch("cart/addItem", this.item);
    },
    async addToWatchlist() {
      this.watchlistLoading = true;
      await this.$store.dispatch("watchlist/addItem", this.item);
      this.watchlistLoading = false;
    },
    async removeFromWatchlist(){
      this.watchlistLoading = true;
      await this.$store.dispatch("watchlist/removeItem", this.item);
      this.watchlistLoading = false;
    },
    checkOut() {
      this.$router.push("/cart");
    },
  },
  watch: {
    async $route() {
      console.log("Route changed");
      await this.loadItemFromRoute();
      this.$forceUpdate();
      $('#itemDb').animate({ scrollTop: 0 }, 200);
    }
  }
}
</script>

<style lang="scss" >
@import "@/scss/item.scss";

.card-price {
    display: inline-block;
    text-align: center;
    width: 100%;
    color: #7BAA64;
    font-size: 2em;
    font-weight: 500;

    text-align: center;
}
// .material-design-icon > .plus-icon > .icon-custom {
//   height:24px;
// }

.stickers-display {
    display: inline-flex;
    margin-top:-5px;
}
.sticker-display {
    margin: 0px 3px 0px 3px;
    height: 20px;
    width: 22px;
    img {
        height: 20px;
        width: 22px;
    }
}

.sticker-wear-alert-icon {
  position: relative;
  bottom: 34px;
  left: 10px;
  width: 50%;
}

.tradable-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  margin-top:-5px;
  color: #545454;
}
</style>